import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
  {
    id: 'ecommerce',
    title: 'Tienda ',
    type: 'item',
    icon: <Icon.ShoppingCart size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/ecommerce',
  },
  {
    id: 'ecommerce.pedidos',
    title: 'Pedidos',
    type: 'item',
    icon: <Icon.Package size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/pedidos',
  },
  {
    id: 'planning',
    title: 'Planificaciones ',
    type: 'item',
    icon: <Icon.Calendar size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/planning',
  },
  {
    id: 'faqs',
    title: 'Centro de ayuda',
    type: 'item',
    icon: <Icon.Info size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/faqs',
  },
];

export default navigationConfig;
