export const FORMULA_INSTANCE_IDS = {
  ON_ADD_TO_CART: 1,
  ON_GO_TO_CHECKOUT: 2,
  ON_PLUS_PRODUCT: 3,
  ON_ORDER_CONFIRM: 4,
};

export const PURCHASE_TYPE_TO_IMPORT = 2;
export const RulesSolutionsConstants = {
  SACAR_DE_ORDEN: 1,
  ACTUALIZAR_ORDEN: 2,
  AGREGAR_A_ORDEN: 3,
};
