export default {
  state: {
    catalogue: { retailId: null, catalogue: [] },
    preCart: {},
  },
  reducers: {
    setCatalogue(state, { retailId, catalogue }) {
      return {
        ...state,
        catalogue: { retailId: retailId, catalogue: catalogue },
      };
    },
    clearCatalogue(state, payload) {
      return { ...state, catalogue: { retailId: null, catalogue: [] } };
    },

    addProductToPrecart(state, p) {
      const key = `${p.retailId}-${p.economicGroupId}-${p.economicGrouIdExterno}-${p.isPlaning}-${p.products[0].selectedPurchaseTypeId}`;
      let preCart_aux = state.preCart;
      const product = p.products;
      if (preCart_aux[key]) {
        const indx = preCart_aux[key]?.products.findIndex(
          (p) =>
            p.productPresentationId === product[0].productPresentationId &&
            p.productsId === product[0].productsId &&
            p.productSKU === product[0].productSKU &&
            p.distributorSupplierId === product[0].distributorSupplierId &&
            p.warehouseId === product[0].warehouseId &&
            p.selectedPurchaseTypeId === product[0].selectedPurchaseTypeId,
        );
        if (indx !== -1) {
          preCart_aux[key].products[indx].cant = product[0].cant;
        } else {
          preCart_aux[key].products = [
            ...preCart_aux[key].products,
            product[0],
          ];
        }
      } else {
        preCart_aux[key] = {
          retailId: p.retailId,
          economicGroupId: p.economicGroupId,
          economicGrouIdExterno: p.economicGrouIdExterno,
          isPlaning: p.isPlaning,
          selectedPurchaseTypeId: p.products[0].selectedPurchaseTypeId,
          products: [product[0]],
        };
      }
      return { ...state, preCart: preCart_aux };
    },
    clearPreCart(state) {
      return { ...state, preCart: new Object() };
    },
  },
  effects: (dispatch) => ({}),
};
