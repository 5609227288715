import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Cumplimiento as cul } from '../../../../@Mocks/Balances.mock';
import ProductList from '../ProductList';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Collapse,
  Badge,
  CardFooter,
} from 'reactstrap';
import {
  ChevronDown,
  ChevronUp,
  Maximize,
  Minimize,
  Trash2,
} from 'react-feather';
import classnames from 'classnames';
import Currency from '../../Currency';

const OrderMobile = (props) => {
  const [collapse, setCollapse] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false);
  const { order } = props;
  const retail = props.retails.find((r) => r.retailId === order.retailId);
  // const [order, setOrder] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [bultos, setBultos] = React.useState(0);
  const { orderIndex } = props;
  const [planned, setPlanned] = React.useState(0);
  const [percentPlanned, setPercentPlanned] = React.useState(null);
  useEffect(() => {
    // setOrder(getOrder(orderIndex));

    const weightInCart = order.productList.reduce(
      (acc, p) => acc + p.weight * p.cant,
      0,
    );
    if (planned === 0) {
      setPercentPlanned(100);
    } else {
      setPercentPlanned(((weightInCart * 100) / planned).toFixed(2));
    }
    if (order) {
      setTotal(getTotal());
      setBultos(getBultos());
    }
  }, [props.cart, planned]);

  useEffect(() => {
    plan();
  }, [props.goals, order]);
  useEffect(() => {
    plan();
  }, []);

  const plan = () => {
    const goalByProvider = props.goals.find(
      (g) => g.goalCategoryId === order.supplierId,
    );
    setPlanned(goalByProvider ? goalByProvider.plannedForToday : 0);
  };

  const state = {
    reload: false,
    isVisible: true,
    status: 'Opened',
    isVisibleAll: true,
    reloadAll: false,
    collapseAll: true,
    statusAll: 'Opened',
  };

  const toggle = () => {
    setCollapse((prev) => !prev);
  };
  const toggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };

  const handleDelete = (orderId) => {
    props.removeOrderFromCart(orderId);
  };

  const getBultos = () =>
    order.productList.length > 0
      ? order.productList.reduce(
          (accumulator, current) =>
            accumulator +
            (current.isCombo
              ? current.products.reduce(
                  (bultos, prod) => bultos + prod.quantityInCombo,
                  0,
                ) * current.cant
              : current.cant),
          0,
        )
      : 0;

  const getTotal = () =>
    order.productList.length > 0
      ? order.productList.reduce(
          (accumulator, current) =>
            accumulator +
            (current.purchaseType.price +
              current.taxes.reduce((acc, curr) => acc + curr.taxAmount, 0)) *
              current.cant,
          0,
        )
      : 0;

  return (
    <>
      {order && (
        <Card
          className={classnames('card-action position-relative mb-0', {
            'card-collapsed': state.status === 'Closed',
            'card-shown': state.status === 'Opened',
            closing: state.status === 'Closing...',
            opening: state.status === 'Opening...',
            'card-fullscreen': fullScreen,
          })}
        >
          <CardHeader onClick={toggle}>
            <CardTitle className="d-flex justify-content-between w-100">
              <div className="responsiveText mt-1">
                {/* <h4 className="card-title">
                  <span className="d-none d-md-inline"> Pedido:</span>
                  {order.id}{" "}
                </h4> */}
                <small className="text-xs d-block pt-0">
                  Cod: {order.cartId}
                </small>
                <h6 className="card-title">
                  <span className="d-none d-md-inline"> Destino:</span>
                  {retail
                    ? retail.retailIdExterno
                      ? retail.retailIdExterno
                      : retail.retailId
                    : order.retailId}{' '}
                  - {retail?.name}
                </h6>
                <h6 className="card-title">
                  <span className="d-none d-md-inline mr-1"> Compra:</span>
                  <Badge
                    pill
                    className={`"badge badge-square badge-light-${
                      window.config.purchaseTypeIdClases[order.purchaseType]
                    } badge-md  mr-1"`}
                  >
                    {window.config.purchaseTypeIdBadge[order.purchaseType] ??
                      order.purchaseTypeName}
                  </Badge>
                </h6>
                <h6 className="card-title mb-0">
                  <span className="d-none d-md-inline"> Distribuye:</span>
                  {order.distributorSupplier
                    ? order.distributorSupplier
                    : order.supplier}{' '}
                  {/* <small>({order.warehouseName})</small> */}
                </h6>{' '}
              </div>

              <div className="d-flex flex-column">
                <div className="actions align-self-end mt-n1">
                  {collapse ? (
                    <ChevronUp className="collapse-icon m-0 m-md-1" size={15} />
                  ) : (
                    <ChevronDown
                      className="collapse-icon m-0 m-md-1"
                      size={15}
                    />
                  )}
                  {fullScreen ? (
                    <Minimize
                      className="collapse-icon  m-1 m-md-1"
                      size={15}
                      onClick={toggleFullScreen}
                    />
                  ) : (
                    <Maximize
                      className="collapse-icon  m-1 m-md-1"
                      size={15}
                      onClick={toggleFullScreen}
                    />
                  )}

                  <Trash2
                    className="collapse-icon  m-0 m-md-1"
                    size={15}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(props.cart[orderIndex].id);
                    }}
                  />
                </div>
                {percentPlanned >= 100 ? (
                  <Badge pill color="success" className="badge-sm mb-05">
                    Llegaste al objetivo
                  </Badge>
                ) : (
                  <Badge pill color="warning" className="badge-sm mb-05">
                    + {percentPlanned}% Cumplimiento
                  </Badge>
                )}
                <Badge
                  pill
                  color={
                    props.pallets[order.id]?.percLastPallet < 50 &&
                    props.pallets[order.id]?.percLastPallet > 0
                      ? 'danger'
                      : props.pallets[order.id]?.percLastPallet < 90 &&
                        props.pallets[order.id]?.percLastPallet > 0
                      ? 'warning'
                      : 'success'
                  }
                  className="badge-sm"
                >
                  {props.pallets[order.id]?.percLastPallet === '00'
                    ? '100% último pallet'
                    : props.pallets[order.id]?.percLastPallet +
                      '% último pallet'}
                </Badge>
              </div>
            </CardTitle>
          </CardHeader>
          <Collapse isOpen={collapse}>
            <CardBody>
              <div className="email-application position-relative">
                <div
                  className={`app-content-overlay ${
                    // state.composeMailStatus || state.sidebarOpen
                    true ? 'show' : ''
                  }`}
                  // onClick={handleMainAndComposeSidebar}
                >
                  <ProductList orderIndex={orderIndex} mainSidebar={true} currency={props.currency ? props.currency : ''}/>
                </div>
              </div>
              <CardFooter className="d-flex justify-content-between bg-white mt-n1 pt-1 pb-0 ">
                <strong>Total:</strong>
                <strong>{bultos} bultos</strong>
                <strong>{props.pallets[order.id]?.cantPallets} pallets</strong>
                <strong>
                  <Currency value={total} currency={props.currency ? props.currency : ''}> </Currency>
                </strong>
              </CardFooter>
            </CardBody>
          </Collapse>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.ecommerce.cart,
  goals: cul, //state.ecommerce.balance,
  retails: state.ecommerce.retails,

  pallets: state.ecommerce.pallets,
});

const mapDispatchToProps = (dispatch) => ({
  removeOrderFromCart: (orderId) =>
    dispatch.ecommerce.removeOrderFromCart(orderId),
  calculatePallets: () => dispatch.ecommerce.calculatePallets(),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderMobile);
