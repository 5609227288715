import mock from '../mock';

export const userFranchises = [
  {
    id: '1000523',
    fantasyName: 'Maipu I',
    direction:
      'Calle Falsa 123 Esquina 9 de Julio - Ciudad autonoma de Buenos Aires',
  },
  {
    id: '1000453',
    fantasyName: 'Maipu II',
    direction: 'Calle Maipu N° 564 - Cordoba - Cordoba',
  },
  {
    id: '1000363',
    fantasyName: 'Maipu III',
    direction: 'Calle 14 N° 4353 - La Plata - Buenos Aires',
  },
  {
    id: '1000873',
    fantasyName: 'Maipu IV',
    direction: 'Calle Martinez N° 328 - Resistencia - Chaco',
  },
];

mock.onGet('/user/franchises').reply(200, {
  userFranchises,
});
