import axios from 'axios';
import TimeService from './TimeService';

const instance = axios.create({
  baseURL: window.config?.timeApi?.baseurl,
});

// const setToken = (jwt) => {
//   instance.defaults.headers.common["Authorization"] = "Bearer " + jwt;
// };

const ApiTime = {
  TimeService: TimeService(instance),
  //   setToken: setToken,
};
export default ApiTime;
