import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';

const Currency = (props) => {
  const formatCurrency = (value) => {
    const value_parts = value.split('.');
    return (
      <h6
        className={`item-price mb-0 ${
          props.className ? props.className : null
        } `}
      >
        {value_parts[0]}
        <sup className="item-price">{value_parts[1]}</sup>
        {props.currency ? ' '+props.currency : ''}
      </h6>
    );
  };

  return (
    <div className="product-price">
      <CurrencyFormat
        prefix={'$ '}
        className={props.className ? props.className : null}
        value={parseFloat(parseFloat(props.value).toFixed(2))}
        renderText={formatCurrency}
        displayType={'text'}
        decimalSeparator={'.'}
        thousandSeparator={','}
      />
    </div>
  );
};

const mapState = (state) => ({
  //  78  config: state.auth.config,
});

export default connect(mapState, null)(Currency);
