export default {
  state: {
    token: null,
    languagesId: 1,
    userProfile: {},
    userId: 1311826,
  },
  reducers: {
    setToken(state, token) {
      return Object.assign({}, { ...state, token: token });
    },
    setLanguage(state, languagesId) {
      return Object.assign({}, { ...state, languagesId: languagesId });
    },
    setUser(state, token, userProfile) {
      return Object.assign({}, { ...state, token: token, userProfile: {} });
    },
    logout(state) {
      return Object.assign(
        {},
        { token: null, languagesId: 1, userProfile: {}, userId: 1311826 },
      );
    },
  },
  effects: {},
};
