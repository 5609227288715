import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OrderMobile from './Order/OrderMobile';

import { Card } from 'reactstrap';
import '../../../assets/scss/pages/app-email.scss';
import '../../../assets/scss/Custom/Cart.scss';

const Cart = (props) => {
  const [cart, setCart] = useState(props.cart);
  useEffect(() => {
    setCart(props.cart);
  }, [props.cart]);
  return (
    <>
      {cart.map((order, orderIndex) => (
        <Card key={orderIndex}>
          <OrderMobile orderIndex={orderIndex} order={order} currency={props.currency ? props.currency : ''}></OrderMobile>
        </Card>
      ))}
      {cart.length === 0 ? (
        <div
          className="d-flex text-center flex-column justify-content-center"
          style={{ minHeight: '60vh' }}
        >
          <h1 className={'light'}> Tu carrito está vacio</h1>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.ecommerce.cart,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
