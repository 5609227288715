import React from 'react';
import { Navbar, NavItem, NavLink } from 'reactstrap';
import * as Icon from 'react-feather';

import classnames from 'classnames';
import NavbarUser from './NavbarUser';
import userImg from '../../../assets/img/portrait/small/avatar-s-11.jpg';
import NavbarRetail from './NavbarRetail';
import { withBreakpoints } from 'react-breakpoints';
import { connect } from 'react-redux';
const ThemeNavbar = (props) => {
  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
  const { breakpoints, currentBreakpoint } = props;

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              props.navbarColor === 'default' ||
              !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          },
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="bookmark-wrapper">
              <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="navbar-nav d-xl-none">
                  <NavItem className="mobile-menu mr-auto">
                    <NavLink
                      className="nav-menu-main menu-toggle hidden-xs is-active"
                      onClick={props.sidebarVisibility}
                    >
                      <Icon.Menu className="ficon" />
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </div>
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              {breakpoints[currentBreakpoint] <= breakpoints.tabletLandscape ? (
                <NavbarRetail disabled={props.planning.isPlanning} />
              ) : (
                <div></div>
              )}

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userImg={userImg}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  planning: state.ecommerce.planning,
});

export default connect(mapStateToProps, null)(withBreakpoints(ThemeNavbar));
