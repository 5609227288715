import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Layout } from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import { init } from '@rematch/core';
import { IntlProvider } from 'react-intl';
import createRematchPersist from '@rematch/persist';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ReactBreakpoints from 'react-breakpoints';

import thunk from 'redux-thunk';
import './index.scss';
import './@fake-db';
import * as models from './redux';
import customizerReducer from './redux/reducers/customizer';
import authReducers from './redux/reducers/auth';
import navbarReducer from './redux/reducers/navbar/Index';
import messages_es from './assets/data/locales/es.json';
import TagManager from 'react-gtm-module';

TagManager.initialize(window.config.tagManagerArgs);
const menu_messages = {
  es: messages_es,
};
const LazyApp = lazy(() => import('./App'));

const persistPlugin = createRematchPersist({
  whitelist: ['country', 'sso', 'ecommerce'],
  blacklist: ['ecommerce.preCart'],
  throttle: 1000,
  version: 1,
});
export const store = init({
  models,
  redux: {
    devtoolOptions: {
      disabled: process.env.REACT_APP_REDUX_DEVTOOLS_DISABLED === 'true',
    },
    reducers: {
      customizer: customizerReducer,
      auth: authReducers,
      navbar: navbarReducer,
    },
    middlewares: [thunk],
  },
  plugins: [persistPlugin],
});
// configureDatabase()
const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
};

const persistor = getPersistor();
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ReactBreakpoints breakpoints={breakpoints}>
        <Suspense fallback={<Spinner />}>
          <Layout>
            <IntlProvider
              key={'es'}
              locale={'es'}
              messages={menu_messages['es']}
              defaultLocale="es"
            >
              <LazyApp />
            </IntlProvider>
          </Layout>
        </Suspense>{' '}
      </ReactBreakpoints>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
