export const Balances = [
  {
    franchiseeName: 'GRIDO LA PLATA SA',
    supplierId: 1,
    supplierName: 'FRIZZIO',
    dueBalance: 23,
    balance: 100,
    percentageExceeded: 5.3,
  },
  {
    franchiseeName: 'GRIDO LA PLATA SA',
    supplierId: 1,
    supplierName: 'Helacor',
    dueBalance: 0,
    balance: -3400,
    percentageExceeded: 5.3,
  },
];

export const Logistica = [
  {
    dia: 1,
    bultos: 6,
    PalletsAEntregar: 1,
    OptimizacionUltimoPallet: 50,
    PosicionesOcupadasEnCamion: 1,
    PalletsPlanificados: 3,
    Cumplimiento: 33.33,
  },
];
export const Cumplimiento = [
  {
    goalCategoryId: 24,
    franchiseeName: 'GRIDO LA PLATA SA',
    goalCategoryName: 'HELACOR',
    month: 9,
    year: 2020,
    goal: 2891.34,
    accumulatedGoal: 4300.88,
    currentMonthGoalAchieved: 18.72,
    plannedForToday: 54.6,
  },
  {
    goalCategoryId: 1,
    franchiseeName: 'GRIDO LA PLATA SA',
    goalCategoryName: 'FRIZZIO',
    month: 9,
    year: 2020,
    goal: 2891.34,
    accumulatedGoal: 4300.88,
    currentMonthGoalAchieved: 18.72,
    plannedForToday: 54.6,
  },
];
