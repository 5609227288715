import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import themeConfig from '../configs/themeConfig';

const FullPageLayout = ({ children, ...rest }) => {
  const location = useLocation();
  const isInicioPage = location.pathname === '/Inicio';

  return (
    <div
      className={classnames(
        'full-layout wrapper bg-full-screen-image blank-page dark-layout',
        {
          'layout-dark': themeConfig.layoutDark,
        },
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div
              className="flexbox-container"
              style={
                isInicioPage
                  ? { height: 'auto', minHeight: '100vh', overflow: 'auto' }
                  : {}
              }
            >
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageLayout;
