import { connect } from 'react-redux';
import React from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import Avatar from '../../../components/@vuexy/avatar/AvatarComponent';
import axios from 'axios';
import * as Icon from 'react-feather';
import { history } from '../../../history';
import { parseJwt } from '../../../utility/context/parseJWT';

const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={(e) => history.push('/LogOut')}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Cerrar sesión</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get('/api/main-search/data').then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.name + ' ' + this.props.lastName}
              </span>
              <span className="user-status">{this.props.username}</span>
            </div>
            <span data-tour="user">
              <Avatar icon={<Icon.User />}></Avatar>
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  const { name, lastName, username } = parseJwt(state.sso.token);
  return {
    name,
    lastName,
    username,
  };
};

export default connect(mapStateToProps, null)(NavbarUser);
