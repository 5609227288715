export const TypeEcommerceFormats = {
  1: 'a la Carta',
  2: 'por Base',
  3: 'Monoproducto',
};

export const TypeEcommerceFormatsId = {
  Carta: 1,
  Base: 2,
  Monoproducto: 3,
};
